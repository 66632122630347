<template>
    <div v-loading="loading">
        <el-row :gutter="5">
            <el-form
                ref="elForm"
                :model="formData"
                :rules="rules"
                size="small"
                label-width="120px"
            >
                <el-col :span="11">
                    <el-form-item label="用户名" prop="User_Name">
                        <el-input
                            v-model="formData.User_Name"
                            placeholder="请输入用户姓名"
                            clearable
                            :style="{width: '100%'}"
                        >
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="11">
                    <el-form-item label="登录名" prop="Login_Name">
                        <el-input
                            v-model="formData.Login_Name"
                            placeholder="请输入登录名"
                            clearable
                            :style="{width: '100%'}"
                        >
                        </el-input>
                    </el-form-item>
                </el-col>
                <!-- <el-col :span="11">
                    <el-form-item label="身份证" prop="Certificate_Number">
                        <el-input
                            v-model="formData.Certificate_Number"
                            placeholder="请输入身份证"
                            clearable
                            :style="{width: '100%'}"
                        >
                            <template slot="append">
                                <div class="checkCard" @click="checkCard">
                                    检测
                                </div>
                            </template>
                        </el-input>
                    </el-form-item>
                </el-col> -->
                <el-col :span="11">
                    <el-form-item label="账号类型" prop="User_Type">
                        <el-select
                            v-model="formData.User_Type"
                            placeholder="请选择账号类型"
                            clearable
                            :style="{width: '100%'}"
                        >
                            <el-option
                                v-for="(item, index) in User_TypeOptions"
                                :key="index"
                                :label="item.label"
                                :value="item.value"
                                :disabled="item.disabled"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="11">
                    <el-form-item label="手机号" prop="Mobile">
                        <el-input
                            v-model="formData.Mobile"
                            placeholder="请输入手机号"
                            clearable
                            :style="{width: '100%'}"
                        >
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="11">
                    <el-form-item label="性别" prop="User_Age">
                        <el-radio-group v-model="formData.User_Gender" size="medium">
                            <el-radio
                                v-for="(item, index) in User_AgeOptions"
                                :key="index"
                                :label="item.value"
                                :disabled="item.disabled"
                            >
                                {{ item.label }}
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="11">
                    <el-form-item label="所属角色">
                        <el-input
                            :value="Role_Name||''"
                            placeholder=""
                            clearable
                            readonly
                            :style="{width: '100%'}"
                        >
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="11">
                    <el-form-item label="所属部门">
                        <el-input
                            :value="Dept_Name||''"
                            placeholder=""
                            readonly
                            clearable
                            :style="{width: '100%'}"
                        >
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="11">
                    <el-form-item label="年龄" prop="User_Age">
                        <el-input
                            v-model="formData.User_Age"
                            placeholder="请输入年龄"
                            clearable
                            :style="{width: '100%'}"
                        >
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="11">
                    <el-form-item label="登录权限">
                        <el-checkbox-group v-model="formData.LogonRights">
                            <el-checkbox label="1">
                                web端
                            </el-checkbox>
                            <el-checkbox label="2">
                                移动端
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                </el-col>
                <el-col :span="22">
                    <el-form-item label="职务/岗位/工种" prop="User_Post">
                        <el-input
                            v-model="formData.User_Post"
                            placeholder="请输入职务/岗位/工种"
                            clearable
                            :style="{width: '100%'}"
                        ></el-input>
                    </el-form-item>
                </el-col>
                <!-- <el-col :span="22">
                    <el-form-item label="签名图片" prop="Sign_Picture">
                        <div class="avatar-uploader">
                            <el-image
                                class="avatar-img"
                                v-if="signPictureUrl"
                                :src="signPictureUrl"
                                :preview-src-list="[signPictureUrl]"
                                fit="cover"
                            ></el-image>
                            <el-upload
                                action=""
                                :file-list="fileList"
                                :show-file-list="false"
                                :auto-upload="false"
                                title="上传"
                                :on-change="onChangeFile"
                            >
                                <i class="el-icon-upload avatar-uploader-icon"></i>
                            </el-upload>
                        </div>
                    </el-form-item>
                </el-col> -->
            </el-form>
        </el-row>
        <div class="dialog-footer">
            <div class="btn save-btn" @click="submitForm">
                保存
            </div>
            <div class="btn close-btn" @click="handleClose">
                关闭
            </div>
        </div>

        <!-- vueCropper 剪裁图片实现-->
        <el-dialog title="图片剪裁" :visible.sync="cropperVisible" append-to-body>
            <div class="cropper-content">
                <div class="cropper" style="text-align:center">
                    <vueCropper
                        ref="cropper"
                        :img="option.img"
                        :output-size="option.size"
                        :output-type="option.outputType"
                        :info="true"
                        :full="option.full"
                        :can-move="option.canMove"
                        :can-move-box="option.canMoveBox"
                        :original="option.original"
                        :auto-crop="option.autoCrop"
                        :fixed="option.fixed"
                        :fixed-number="option.fixedNumber"
                        :center-box="option.centerBox"
                        :info-true="option.infoTrue"
                        :fixed-box="option.fixedBox"
                    ></vueCropper>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <div class="btn save-btn" @click="finishCropper">
                    确认
                </div>
                <div class="btn close-btn" @click="cropperVisible = false">
                    取消
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import md5 from 'js-md5';
import utils from '@/utils/util';
import { VueCropper } from 'vue-cropper';
export default {
    components: { VueCropper },
    props: {
        userId: String,
    },
    data() {
        // const checkIdNum = (rule, value, callback) => {
        //     const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        //     if (!value) {
        //         return callback(new Error('请输入证件号码'));
        //     } if (!reg.test(value)) {
        //         return callback(new Error('证件号码不正确'));
        //     }
        //     callback();
        // };
        return {
            aliyunOssToken: null,
            buCode: 'ProductionAffix',
            loading: false,
            tenant_id: '',
            Role_Name: '',
            Dept_Name: '',
            formData: {
                User_Name: '',
                Login_Name: '',
                Certificate_Number: '',
                User_Type: 1,
                Mobile: '',
                User_Gender: 1,
                User_Age: '',
                User_Post: '',
                Sign_Picture: '',
                LogonRights: [],
            },
            rules: {
                User_Name: [{
                    required: true,
                    message: '请输入用户名',
                    trigger: 'blur',
                }],
                Login_Name: [{
                    required: true,
                    message: '请输入登录名',
                    trigger: 'blur',
                }, {
                    pattern: /^1[3-9]\d{9}$/,
                    message: '格式错误, 请输入手机号',
                    trigger: 'blur',
                }],
                Mobile: [{
                    required: true,
                    message: '请输入手机号',
                    trigger: 'blur',
                }, {
                    pattern: /^1[3-9]\d{9}$/,
                    message: '手机号格式错误',
                    trigger: 'blur',
                }],
                // Certificate_Number: [{ required: false, validator: checkIdNum }],
            },
            User_TypeOptions: [{
                label: '内部用户',
                value: 1,
            }, {
                label: '司机',
                value: 2,
            }, {
                label: '客户',
                value: 3,
            }, {
                label: '供应商',
                value: 4,
            }, {
                label: '运输单位',
                value: 5,
            }],
            User_AgeOptions: [{
                label: '男',
                value: 1,
            }, {
                label: '女',
                value: 0,
            }],

            fileList: [],

            // 裁剪图片弹框
            cropperVisible: false,
            option: {
                img: '', // 裁剪图片的地址
                info: true, // 裁剪框的大小信息
                outputSize: 1, // 裁剪生成图片的质量
                outputType: 'jpeg', // 裁剪生成图片的格式
                canScale: false, // 图片是否允许滚轮缩放
                autoCrop: true, // 是否默认生成截图框
                // autoCropWidth: 150, // 默认生成截图框宽度
                // autoCropHeight: 30, // 默认生成截图框高度
                fixedBox: false, // 固定截图框大小 不允许改变
                fixed: true, // 是否开启截图框宽高固定比例
                fixedNumber: [150, 30], // 截图框的宽高比例
                full: true, // 是否输出原图比例的截图
                canMoveBox: true, // 截图框能否拖动
                original: false, // 上传图片按照原始比例渲染
                centerBox: true, // 截图框是否被限制在图片里面
                infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            },
            // 图片地址
            signPictureUrl: '',
        };
    },
    mounted() {
        this.initForm();
        this.getOssToken();
    },
    methods: {
        initForm() {
            this.tenant_id = this.$takeTokenParameters('TenantId');
            this.resetForm();
            if (this.userId) {
                this.getInfo();
            }
        },
        // 获取详情信息
        getInfo() {
            this.$axios.get(`/interfaceApi/basicplatform/users/${this.userId}`)
                .then(res => {
                    if (res) {
                        this.setUserInfo(res);
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 设置用户信息
        setUserInfo(res) {
            this.Role_Name = res.Role_Name;
            this.Dept_Name = res.Dept_Name;
            res.LogonRights = res.LogonRights ? res.LogonRights.split('|') : [];
            this.formData = res;
            if (this.formData.Sign_Picture) {
                // this.signPictureUrl = this.FILE.FILE_BASE_URL() + 'file/' + this.formData.Sign_Picture + '/0';
                this.getFileDetailFun(this.formData.Sign_Picture);
            }
        },
        getFileDetailFun(id, key) {
            this.$axios
                .get(this.FILE.FILE_BASE_URL_OSS() + 'api/GetFileInfo/' + id)
                .then(res => {
                    this.signPictureUrl = res.preview_url;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        submitForm() {
            this.$refs.elForm.validate(async valid => {
                if (!valid) {return;}
                // TODO 提交表单
                this.loading = true;
                try {
                    // 如果有待上传的文件
                    if (this.fileList.length > 0 && this.signPictureUrl) {
                        this.upFun(this.fileList[0]);
                        // this.formData.Sign_Picture = await this.uploadFileMethod(this.fileList[0]);
                    }
                    let submitUrl = '';
                    this.formData.LogonRights = this.formData.LogonRights.join('|');
                    if (this.formData.User_Id) {
                        const data = {
                            Id: this.formData.User_Id,
                            RealName: this.formData.User_Name,
                            LoginName: this.formData.Login_Name,
                            Mobile: this.formData.Mobile,
                            Email: this.formData.Email || '',
                            State: 1,
                            UserType: 1,
                            CertificateType: '1',
                            CertificateNumber: this.formData.Certificate_Number || '',
                            UserCode: this.formData.User_Code || '',
                            UserGender: this.formData.User_Gender,
                            UserAge: this.formData.User_Age || 0,
                            UserPost: this.formData.User_Post || '',
                            OrgId: this.formData.Org_ID || '',
                            OrgName: this.formData.Org_Name || '',
                            RoleInfos: {},
                            TenantId: this.tenant_id,
                            LogonRights: this.formData.LogonRights,
                            IsModifiedPwd: false,
                            Password: '',
                            NewPassword: '',
                            soure_type: 2,
                        };
                        submitUrl = '/interfaceApi/platform/user_manage/modify_and_sync';
                        await this.$axios.put(submitUrl, data);
                    } else {
                        const data = {
                            RealName: this.formData.User_Name,
                            LoginName: this.formData.Login_Name,
                            Email: this.formData.Email,
                            State: 1,
                            UserType: 1,
                            UserGender: this.formData.User_Gender,
                            UserAge: this.formData.User_Age,
                            CertificateType: '1',
                            CertificateNumber: this.formData.Certificate_Number,
                            UserCode: '',
                            UserPost: '',
                            OrgId: '',
                            OrgName: '',
                            RoleInfos: {},
                            TenantId: 'T200103000005',
                            LogonRights: this.formData.LogonRights,
                            soure_type: 2,
                        };
                        submitUrl = '/interfaceApi/platform/user_manage/create_and_sync';
                        await this.$axios.post(submitUrl, data);
                    }
                    this.$message.success('操作成功');
                    this.$emit('handleSucc');
                } catch (error) {
                    if (error.ErrorCode) {
                        this.$message.error(error.ErrorCode.Message);
                    }
                } finally {
                    this.loading = false;
                }
            });
        },
        resetForm() {
            this.Role_Name = '';
            this.Dept_Name = '';
            this.$refs.elForm.resetFields();
        },
        handleClose() {
            this.$emit('handleClose');
        },

        // 检测身份证
        // 6.检测用户身份证号是否存在
        // [get]http://39.106.58.200/interfaceApi/basicplatform/users/ExistsUserByCertificateNumber
        // 7.通过身份证号从运维平台同步用户
        // [get]http://39.106.58.200/interfaceApi/basicplatform/users/ExistsUserByCertificateNumber
        checkCard() {
            if (!this.formData.Certificate_Number) {
                this.$message.warning('请输入检测证件号码');
                return;
            }
            const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
            if (!reg.test(this.formData.Certificate_Number)) {
                this.$message.warning('证件号码不正确');
                return;
            }
            this.$axios
                .get(`/interfaceApi/basicplatform/users/ExistsUserByCertificateNumber?certificate_number=${this.formData.Certificate_Number}`)
                .then(() => {
                    this.$message.info('该身份证号未注册');
                }).catch(res => {
                    if (res.ErrorCode.Code === '1') {
                        this.$message.warning('该身份证号已注册，不能重复注册');
                        this.setUserInfo(res.DataContext);
                    } else if (res.ErrorCode.Code === '2') {
                        this.$confirm('该身份证号已注册，是否同步?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning',
                        }).then(() => {
                            const url = 'interfaceApi/basicplatform/users/CreateUserByCertificateNumber?'
                            + `certificate_number=${this.formData.Certificate_Number}`;
                            this.$axios.get(url)
                                .then(data => {
                                    this.setUserInfo(res);
                                }).catch(e => this.$message(e.ErrorCode.Message));
                        });
                    }
                });
        },
        md5(val) {
            return md5(val.toString());
        },

        // 上传附件
        async uploadFileMethod(file) {
            const formData = new FormData();
            formData.append('files', file);
            formData.append('code', 'ProductionAffix');
            formData.append('data_id', this.userId);
            formData.append('group_id', this.userId);
            const res = await this.$axios.$post(this.FILE.FILE_BASE_URL() + 'file/uploadfile', formData, { defEx: true });
            return res[0];
        },
        // 获取上传通行证
        getOssToken() {
            this.$axios.get(this.FILE.FILE_BASE_URL_OSS() + 'credential/GetPostPolicy/' + this.buCode).then(res => {
                if (res) {
                    this.aliyunOssToken = res;
                }
            });
        },
        // 上传
        upFun(val) {
            const filename = val.name;
            // 判断是否过期，过期重新调用获取通信证方法
            const formData = new FormData();
            // 注意formData里append添加的键的大小写
            formData.append('key', `${this.aliyunOssToken.dir}${this.userId}/${filename}`); // 存储在oss的文件路径
            formData.append('OSSAccessKeyId', this.aliyunOssToken.accessid); // accessKeyId
            formData.append('policy', this.aliyunOssToken.policy); // policy
            formData.append('Signature', this.aliyunOssToken.signature); // 签名
            // 如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
            formData.append('file', val);
            // formData.append('FileDescription', this.newFile.FileDescription);
            formData.append('success_action_status', 200); // 成功后返回的操作码
            formData.append('Content-Type', 'multipart/form-data'); // 成功后返回的操作码

            // 回调参数
            const obj = {
                bucket: this.aliyunOssToken.bucket ? this.aliyunOssToken.bucket : this.buCode, // 存储桶名称
                business_type_code: this.buCode, // 业务类型编码
                object_name: `${this.aliyunOssToken.dir}${this.dataInfo.quality.id}/${filename}`, // 文件对象名
                show_name: filename, // 显示文件名
                size: 0, // 文件大小
                mimeType: '',
                mime_type: val ? val.raw : '',
                data_id: this.userId, // 业务数据id
                directory_id: '', // 目录id
                tenant_id: this.$takeTokenParameters('TenantId'), // 租户id
                // FileDescription: this.newFile.FileDescription, // 文件描述
            };
            this.uploadFun(formData, obj);
        },
        // 上传文件
        uploadFun(formData, val) {
            $.ajax({
                url: this.aliyunOssToken.host,
                type: 'POST',
                data: formData,
                cache: false, // 不缓存数据
                processData: false, // 不处理数据
                contentType: false, // 不设置内容类型
                success: data => { // 成功回调
                    this.callBackFun(val);
                },
            });
        },
        // 上传的回调
        callBackFun(obj) {
            this.$axios.post(this.FILE.FILE_BASE_URL_OSS() + 'api/UploadCallback', obj).then(res => {
                this.formData.Sign_Picture = res.id;
            });
        },
        // 文件选中上传后
        onChangeFile(file) {
            this.cropperVisible = true;
            this.option.img = URL.createObjectURL(file.raw);
            this.fileList = [file.raw];
        },
        // 点击裁剪，
        finishCropper() {
            this.$refs.cropper.getCropBlob(data => {
                this.signPictureUrl = URL.createObjectURL(data);
                const oldFile = this.fileList[0];
                const newFile = new File([data], oldFile.name, {
                    type: oldFile.type,
                });
                this.fileList = [newFile];
                this.cropperVisible = false;
            });
        },
    },
};

</script>
<style lang="stylus" scoped>
    /* 截图 */
    .cropper
        width: auto;
        height: 3rem;
    >>> .el-input-group__append
        width 0.7rem;
        height 100%;
        border 0;
        background #D5B588
        color #fff;
        cursor: pointer;
        padding 0;
        .checkCard
            width 100%;
            height 100%;
            text-align center;
    .dialog-footer
        padding 0.2rem 0 0;
        display flex
        justify-content center
        .btn
            margin 0 0.3rem;
            width: 1.3rem;
            height: 0.36rem;
            line-height 0.36rem;
            text-align center
            border-radius: 0.01rem;
            cursor pointer
        .save-btn
            background: #1577D2;
            color #fff;
        .close-btn
            border: 0.01rem solid #1577D2;
            color #1577D2
    .avatar-uploader
        min-height 32px
        display flex
        align-items center
        >>> .el-upload
            display flex
            align-items center
            margin-left 0.1rem
            cursor: pointer;
            position: relative;
            overflow: hidden;
            .el-icon-upload
                font-size 0.24rem
                color #1577d2
        .avatar-img
            width 1.5rem;
            height 0.3rem;
</style>
